// @ts-check
import React from 'react';
import { Drawer, List, Stack } from '@mui/material';
import Divider from '@mui/material/Divider';
import { NavBarItem } from './NavBarItem/NavBarItem';
import { Typography } from '../../newComponents/Typography/Typography';
import Logo from '../../components/Logos/Imagotipo/Sora_Logo.png';
import IconLogo from '../../components/Logos/Isotipo/Imagotipo.png';
import Box from '@mui/material/Box';
import { ScrollableBox, dynamicStyles, styles } from './NavBar.styles';
import { useReactiveVar } from '@apollo/client';
import { currentRoleVar, userCompaniesVar } from '../../cache.reactiveVars';
import {
  adminNavBarItems,
  employeeNavBarItems,
  othersNavBarItems,
} from './NavBar.constants';
import { Icon } from '../../components/Icons/Icons';
import { FabButton } from '../../newComponents/FabButton';
import { navBarStateVar } from './NavBar.vars';
import { useTheme } from '@mui/material/styles';
import { useScreenSize } from '../../Hooks';
import CompanySelect from '../../components/CompanySelect/CompanySelect';
import { useScreenHeight } from '../../Hooks/useScreenHeight';

export const NavBar = () => {
  const navBarState = useReactiveVar(navBarStateVar);
  const companies = useReactiveVar(userCompaniesVar);
  const currentRole = useReactiveVar(currentRoleVar);
  const isMaster = currentRole?.isMaster;
  const isAdminRh = currentRole?.isAdminRh;
  const isWcAdmin = currentRole?.isAdmin?.length;
  const isSomeAdmin = isMaster || isAdminRh || isWcAdmin;
  const isEmployee = currentRole?.isEmployee;
  /** @type {import('../../theme').CustomTheme} */
  const theme = useTheme();

  const handleExpandDrawer = () => {
    navBarStateVar({ expanded: !navBarState.expanded });
  };

  const { isMobile } = useScreenSize();
  const screenHeight = useScreenHeight();

  const drawerWidth = navBarState.expanded ? 212 : 88;
  const style = navBarState.expanded ? styles.expanded : styles.mini;
  const dynamicStyle = dynamicStyles({ drawerWidth, theme, screenHeight });
  const expandIcon = navBarState.expanded
    ? 'arrow_left_s_line'
    : 'arrow_right_s_line';

  return (
    <>
      {!isMobile && (
        <FabButton
          sx={dynamicStyle.fabButton}
          size="small"
          onClick={handleExpandDrawer}
        >
          <Icon icon={expandIcon} height="18px" color="grey" />
        </FabButton>
      )}
      <Drawer
        variant={isMobile ? 'temporary' : 'permanent'}
        open={navBarState.expanded}
        onClose={handleExpandDrawer}
        sx={dynamicStyle.drawer}
      >
        {isMobile ? (
          <Box padding="8px">
            <CompanySelect />
          </Box>
        ) : (
          <Box sx={style.imgContanier}>
            <img
              alt="Sora Logo"
              height={40}
              src={navBarState.expanded ? Logo : IconLogo}
            />
          </Box>
        )}
        <Box sx={style.divider}>
          <Divider />
        </Box>
        <Stack sx={dynamicStyle.drawerContent}>
          <ScrollableBox>
            {isSomeAdmin && companies?.length > 0 ? (
              <Box sx={style.listContainer}>
                <Box sx={style.listTitle}>
                  <Typography
                    variant="overline"
                    color="primary"
                    sx={{
                      fontSize: '11px !important',
                    }}
                  >
                    {navBarState.expanded ? 'ADMINISTRADOR' : 'ADMIN'}
                  </Typography>
                </Box>
                <List sx={style.list}>
                  {adminNavBarItems.map((item, index) => (
                    <NavBarItem
                      key={index}
                      item={item}
                      expanded={navBarState.expanded}
                      activeColor="primary"
                      type="ADMIN"
                    />
                  ))}
                </List>
              </Box>
            ) : null}
            {isEmployee && companies?.length > 0 ? (
              <>
                <Box sx={style.divider}>
                  <Divider />
                </Box>
                <div id="my-options-sm-tour">
                  <Box sx={style.listContainer}>
                    <Box sx={style.listTitle}>
                      <Typography
                        variant="overline"
                        color="secondary"
                        sx={{
                          fontSize: '11px !important',
                        }}
                      >
                        EMPLEADO
                      </Typography>
                    </Box>
                    <List sx={style.list}>
                      {employeeNavBarItems.map((item, index) => (
                        <NavBarItem
                          key={index}
                          item={item}
                          expanded={navBarState.expanded}
                          activeColor="secondary"
                          type="EMPLOYEE"
                          id={item.tourId}
                        />
                      ))}
                    </List>
                  </Box>
                </div>
              </>
            ) : null}
          </ScrollableBox>
          <Box>
            <Box sx={style.divider}>
              <Divider />
            </Box>
            <Box pt="4px" sx={style.listContainer}>
              <List sx={style.list}>
                {othersNavBarItems.map((item, index) => {
                  if (isWcAdmin) return null;
                  if (item.id === 'settings' && isEmployee && !isSomeAdmin) {
                    return null;
                  }
                  return (
                    <NavBarItem
                      key={index}
                      item={item}
                      expanded={navBarState.expanded}
                      activeColor="grey"
                      type="OTHER"
                      id={item.tourId}
                    />
                  );
                })}
              </List>
            </Box>
          </Box>
        </Stack>
      </Drawer>
    </>
  );
};
